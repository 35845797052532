import { ThreadModel } from "../../../domain/models/thread-model"


export const threadMapper = (resp: any): ThreadModel => {
  const feed = resp[0]
  return {
    id: feed.id,
    retro: feed.retro,
    networks: feed.networks
  }
}
