import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FallOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ru_RU";
import { summaryService } from "../api";
import { useSelector } from "react-redux";
import { getProduct, getThread } from "../redux/selectors";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { calcChange, findSource } from "../scripts/utils";
import NetworkIcon from "../scripts/network-icon";
import CoverageDouble from "./CoverageDouble";

const MediaIndex: React.FC = () => {
  const [value, setValue] = useState("today");
  const thread = useSelector(getThread);
  const product = useSelector(getProduct);
  const { RangePicker } = DatePicker;
  const feedFilter = useSelector(getFeedFilter);
  const [loading, setLoading] = useState(true);
  const [totalMediaindex, setTotalMediaindex] = useState(0);
  const [client, setClient] = useState("");
  const [previousMediaindex, setPreviousMediaindex] = useState(0);
  const [competitors, setCompetitors] = useState([]);
  const [top, setTop] = useState({ social_networks: [], smi: [] });
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const [open, setOpen] = useState(false);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params = {
        thread_id: +thread.id,
        product_id: product,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
      };
      summaryService
        .loadMediaIndexInfo(params)
        .then((resp) => {
          setCompetitors(resp.competitors.items);
          setTop(resp.client.top);
          setTotalMediaindex(resp.client.media_index);
          setClient(resp.client.title);
          let calc = calcChange(
            resp.client.media_index,
            resp.client.previous.media_index
          );

          setPreviousMediaindex(+calc);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, product]);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  return (
    <div className="grid2_1">
      <div className="white-block-small">
        <div className="flex-space">
          <p className="block-title">
            ERR {client}
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
          <div className="flex">
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    buttonSolidCheckedColor: "#7856FE",
                    buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                    colorPrimary: "rgba(0, 0, 0, 0.1)",
                    buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                optionType="button"
                buttonStyle="solid"
              />
            </ConfigProvider>

            {value == "period" && (
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={onPeriodChange}
                style={{ width: "278px" }}
                className="choose-period"
                allowClear={false}
                locale={locale}
              />
            )}
          </div>
        </div>
        {loading ? (
          <div className="feed-posts-loader" style={{ height: "300px" }}>
            <LoadingOutlined />
          </div>
        ) : (
          <div>
            <div className="flex margin-bottom mobile-flex">
              <div className="title-big">{totalMediaindex}</div>
              <Tooltip
                color={"#7856FE"}
                title="Процент роста/спада показателей, по сравнению с предыдущим периодом"
              >
                {previousMediaindex > 0 ? (
                  <div className="green green-bg">
                    <RiseOutlined /> +{previousMediaindex?.toFixed(2)}%
                  </div>
                ) : (
                  <div className="red red-bg">
                    <FallOutlined /> {previousMediaindex?.toFixed(2)}%
                  </div>
                )}
              </Tooltip>
            </div>
            <p className="block-title">Объекты сравнения</p>
            <div className="grid4 margin-bottom">
              {competitors.map((competitor: any, id) => (
                <div className="flex" key={id}>
                  <div className="user-widget">
                    <div className="user-img">
                      {competitor?.title?.split(" ")[0]?.slice(0, 1).toUpperCase() || ""}{" "}
                      {competitor?.title?.split(" ")[1]?.slice(0, 1).toUpperCase() || ""}
                    </div>
                  </div>
                  <div>
                    <p className="grey">{competitor?.title}</p>
                    <div className="flex-baseline">
                      <div className="title-small">
                        {competitor?.media_index}
                      </div>
                      <Tooltip
                        color={"#7856FE"}
                        title="Процент роста/спада показателей, по сравнению с предыдущим периодом"
                      >
                        <div
                          className={
                            competitor?.previous.media_index >=
                            competitor?.media_index
                              ? "red"
                              : "green"
                          }
                        >
                          {calcChange(
                            competitor?.media_index,
                            competitor?.previous.media_index
                          )}
                          %
                          {competitor?.previous.media_index >=
                          competitor?.media_index ? (
                            <ArrowDownOutlined />
                          ) : (
                            <ArrowUpOutlined />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid2">
              {top.social_networks.length > 0 && (
                <div className="grid-block gray-block">
                  <p className="block-title">В соцсетях</p>
                  {top.social_networks.map((it: any, id) => (
                    <div className="white-block-small grid3-1-1" key={id}>
                      <div className="flex">
                        <NetworkIcon networkName={it.code} />
                        <div className="grey">{findSource(it.code)}</div>
                      </div>
                      <Tooltip
                        color={"#7856FE"}
                        title="Процент роста/спада показателей, по сравнению с предыдущим периодом"
                      >
                        <div
                          className={
                            it.mentions.current <= it.mentions.previous
                              ? `red flex-simple`
                              : `green flex-simple`
                          }
                        >
                          {calcChange(
                            it.mentions.current,
                            it.mentions.previous
                          )}
                          {it.mentions.current <= it.mentions.previous ? (
                            <ArrowDownOutlined />
                          ) : (
                            <ArrowUpOutlined />
                          )}
                        </div>
                      </Tooltip>
                      <div className="block-title">{it.mentions.current}</div>
                    </div>
                  ))}
                </div>
              )}
              {top.smi.length > 0 && (
                <div className="grid-block gray-block">
                  <p className="block-title">В СМИ</p>
                  {top.smi.map((it: any, id) => (
                    <div className="white-block-small grid3-1-1" key={id}>
                      <div className="flex">
                        <img src={it.image} className="source-icon"></img>
                        <div className="grey">{it.username}</div>
                      </div>
                      <Tooltip
                        color={"#7856FE"}
                        title="Процент роста/спада показателей, по сравнению с предыдущим периодом"
                      >
                        <div
                          className={
                            it.mentions.current <= it.mentions.previous
                              ? `red flex-simple`
                              : `green flex-simple`
                          }
                        >
                          {calcChange(
                            it.mentions.current,
                            it.mentions.previous
                          )}{" "}
                          {it.mentions.current <= it.mentions.previous ? (
                            <ArrowDownOutlined />
                          ) : (
                            <ArrowUpOutlined />
                          )}
                        </div>
                      </Tooltip>
                      <div className="block-title">{it.mentions.current}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <CoverageDouble title={["viewed", "attendance"]} />
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <div className="overflow-block">
          <p className="purple-border">
            ERR - «Уровень вовлеченности по охвату». Данный показатель дает
            понимание о том, какой процент подписчиков просматривает публикации
            в рассматриваемых источниках. Именно «просматривает», текст
            публикации на самом деле может быть и не прочитан подписчиком.
            Рассчитывается как отношение среднего охвата публикации к количеству
            подписчиков канала.
          </p>
          <p className="title-small">Как интерпретировать данные</p>
          <ol>
            <li>
              Необходимо выбрать интересующий временной интервал, после чего
              будет сформирован числовой показатель ERR. Рядом с индексом
              отобразится процент роста/спада показателей по сравнению с
              предыдущим периодом.
            </li>
            <li>
              Формула: делим среднее количество просмотров на количество
              подписчиков в канале и умножаем на 100
            </li>
          </ol>
          <p className="title-small">Источники данных</p>
          <ul>
            <li>Telegram</li>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>Instagram</li>
            <li>Вконтакте</li>
            <li>Одноклассники</li>
            <li>Дзен</li>
          </ul>
        </div>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default MediaIndex;
