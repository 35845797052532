import "../App.css";
import NumberOfMentions from "../components/NumberOfMentions";
import TonalStatistic from "../components/TonalStatistic";
import BestSources from "../components/BestSources";
import PublicTop from "../components/PubicTop";
import BestThemes from "../components/BestThemes";
import Metrics from "../components/Metrics";
import MediaIndex from "../components/MediaIndex";
import { resetFeedFilter, setProducts } from "../redux/actions";
import { useAppDispatch } from "../redux/store/hooks";
import { useEffect } from "react";
import { referencesService } from "../api";
import { useSelector } from "react-redux";
import { getProduct } from "../redux/selectors";

function Main() {
  const dispatch = useAppDispatch();
  dispatch(resetFeedFilter());
  const product = useSelector(getProduct);

  useEffect(() => {
    referencesService
      .loadClientProducts()
      .then((resp) => {
        dispatch(setProducts(resp));
      })
      .catch((err) => {
        //dispatch(logoutAuth());
      });
  }, [product]);

  return (
    <div className="App">
      <h1>Главная</h1>
      <NumberOfMentions />
      <TonalStatistic />
      <MediaIndex />
      <BestSources />
      <PublicTop />
      <BestThemes />
      <Metrics />
    </div>
  );
}

export default Main;
