import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Colors,
  LinearScale,
  Tooltip,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { ConfigProvider, Switch } from "antd";
import { getAppIsMobile } from "../redux/selectors";
import { useSelector } from "react-redux";

type Props = {
  graphData: any;
};

const BarChart: any = ({ graphData }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const isMobile = useSelector(getAppIsMobile);

  Chart.register(
    Colors,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    ArcElement,
    ChartDataLabels
  );

  const months = [
    "янв",
    "фев",
    "март",
    "апр",
    "май",
    "июнь",
    "июль",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];

  const getDate = (date: string) => {
    let dateSeparator = "-";

    if (date.includes(dateSeparator)) {
      let dateArr = date.split("-");
      let month = parseInt(dateArr[1]) - 1;
      if (date.length < 10) return months[month] + " " + dateArr[0];
      if (date.length == 10)
        return dateArr[2] + " " + months[month] + " " + dateArr[0];
      if (date.length > 10) return date.slice(-5);
    }

    return date;
  };

  const arr = graphData?.map((it: any) => it.count);
  const arrPositive = graphData?.map((it: any) => it.positive);
  const arrNegative = graphData?.map((it: any) => it.negative);
  const arrNetural = graphData?.map((it: any) => it.netural);
  const arrString = graphData?.map((it: any) => getDate(it.graph_date));

  const arrSource = graphData?.map((it: any) => it?.top?.social_networks);
  const arrSmi = graphData?.map((it: any) => it?.top?.smi);
  const data: any = {
    labels: arrString,
    datasets: [
      {
        label: "Позитивные",
        data: arrPositive,
        backgroundColor: "#09b96d",
        borderColor: "#09b96d",
        borderWidth: 0,
        borderRadius: 5,
        borderSkipped: false,
        datalabels: {
          anchor: "end",
          align: "start",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderColor: "rgba(255, 255, 255, 1)",
          borderRadius: 4,
          borderWidth: 1,
          padding: {
            top: 3,
            bottom: 3,
            left: 2,
            right: 2,
          },
          display: function (value: any) {
            return isChecked
              ? value.dataset.data.map((it: any) => (it > 2 ? true : false))
              : false;
          },
        },
      },
      {
        label: "Негативные",
        data: arrNegative,
        backgroundColor: "#f12f69",
        borderColor: "#f12f69",
        borderWidth: 0,
        borderRadius: 5,
        borderSkipped: false,
        datalabels: {
          anchor: "end",
          align: "start",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderColor: "rgba(255, 255, 255, 1)",
          borderRadius: 4,
          borderWidth: 1,
          padding: {
            top: 3,
            bottom: 3,
            left: 2,
            right: 2,
          },
          display: function (value: any) {
            return isChecked
              ? value.dataset.data.map((it: any) => (it > 2 ? true : false))
              : false;
          },
        },
      },
      {
        label: "Нейтральные",
        data: arrNetural,
        backgroundColor: "#D8CCFB",
        borderColor: "#D8CCFB",
        borderWidth: 0,
        borderRadius: 3,
        borderSkipped: false,
        datalabels: {
          anchor: "end",
          align: "start",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderColor: "rgba(255, 255, 255, 1)",
          borderRadius: 4,
          borderWidth: 1,
          padding: {
            top: 3,
            bottom: 3,
            left: 2,
            right: 2,
          },
          display: function (value: any) {
            return isChecked
              ? value.dataset.data.map((it: any) => (it > 2 ? true : false))
              : false;
          },
        },
      },
    ],
  };

  const onChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  return (
    <div>
      {!isMobile && (
        <div className="flex">
          <p>Показывать данные</p>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#D8CCFB",
                colorPrimaryHover: "#D8CCFB",
                colorTextTertiary: "rgba(0, 0, 0, 0.2)",
                colorTextQuaternary: "rgba(0, 0, 0, 0.1)",
                fontSize: 10,
              },
              components: {
                Switch: {
                  handleSize: 22,
                  trackPadding: -2,
                },
              },
            }}
          >
            <Switch onChange={onChange} />
          </ConfigProvider>
        </div>
      )}
      <Bar
        data={data}
        width={"400px"}
        options={{
          scales: {
            x: {
              display: true,
              clip: false,
              grid: { display: false, lineWidth: 0 },
              border: { display: false, width: 0 },
              stacked: true,
            },
            y: {
              display: true,
              stacked: true,
              border: { display: false, width: 0 },
            },
          },
          /*plugins: {
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,

              external:
                graphData &&
                function (context) {
                  // Tooltip Element
                  let tooltipEl: any =
                    document.getElementById("chartjs-tooltip");

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip";
                    tooltipEl.innerHTML =
                      '<div style="background: white; border-radius: 10px; border: 3px ridge rgba(120, 86, 254, 0.4); box-shadow: 5px 5px 15px #b5b5b7de; text-align: left; padding: 15px"><span style="font-weight: 500; font-size: 12px; line-height: 12px;"> В соцсетях </span><table id="table1" style="border-collapse: collapse; margin-bottom: 15px"></table><hr style = "opacity: 0.3;"><span style="font-weight: 500; font-size: 12px; line-height: 12px;"> В СМИ </span><table id="table2" style="border-collapse: collapse;"></table></div>';
                    document.body.appendChild(tooltipEl);
                  }

                  // Hide if no tooltip
                  const tooltipModel: any = context.tooltip;
                  console.log("BarChart", graphData);
                  let index =
                    graphData &&
                    graphData?.findIndex(
                      (it: any) =>
                        getDate(it?.graph_date) == tooltipModel?.title[0]
                    );
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove("above", "below", "no-transform");
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add("no-transform");
                  }

                  function getBody(bodyItem: any) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body && arrSource[index]?.length > 0) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = "<thead>";

                    titleLines.forEach(function (title: any, i: any) {
                      const colors = tooltipModel.labelColors[i];
                      innerHtml += '<tr style="height: 5px;">';
                      arrSource[index]?.forEach(
                        (it: any, id: number) =>
                          (innerHtml +=
                            `<th key=${id} style="border-left: 2px solid #7856FE; font-weight: 100; font-size: 12px; height: 5px; vertical-align: bottom; line-height: 12px; padding-right: 15px; padding-left: 5px;">` +
                            it.name +
                            "</th>")
                      );
                      innerHtml += "</tr>";
                    });
                    innerHtml += "</thead><tbody>";

                    bodyLines.forEach(function (body: any, i: any) {
                      const colors = tooltipModel.labelColors[i];
                      let style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      //style += '; border-width: 2px';
                      const span =
                        '<span style="' +
                        style +
                        '">' +
                        body +
                        "!!!" +
                        "</span>";
                      //innerHtml += '<tr><td>' + span + '</td></tr>';
                      innerHtml += '<tr style="height: 5px;">';
                      arrSource[index]?.forEach(
                        (it: any, id: number) =>
                          (innerHtml +=
                            `<td key=${id} style="border-left: 2px solid #7856FE; font-weight: 600; font-size: 20px; height: 5px; vertical-align: top; line-height: 20px; text-align: left; padding-right: 15px; padding-left: 5px;">` +
                            it?.mentions +
                            "</td>")
                      );
                      innerHtml += "</tr>";
                    });
                    innerHtml += "</tbody>";

                    let tableRoot: any = tooltipEl.querySelector("#table1");
                    tableRoot.innerHTML = innerHtml;

                    let innerHtml2 = "<thead>";

                    titleLines.forEach(function (title: any, i: any) {
                      const colors = tooltipModel.labelColors[i];
                      innerHtml2 += '<tr style="height: 5px;">';
                      arrSmi[index]?.forEach(
                        (it: any, id: number) =>
                          (innerHtml2 +=
                            `<th key=${id} style="border-left: 2px solid #FF7555; font-weight: 100; font-size: 12px; height: 5px; vertical-align: bottom; line-height: 12px; padding-right: 15px; padding-left: 5px;">` +
                            it?.username +
                            "</th>")
                      );
                      innerHtml2 += "</tr>";
                    });
                    innerHtml2 += "</thead><tbody>";

                    bodyLines.forEach(function (body: any, i: any) {
                      const colors = tooltipModel.labelColors[i];
                      let style = "background:" + colors.backgroundColor;
                      style += "; border-color:" + colors.borderColor;
                      //style += '; border-width: 2px';
                      const span =
                        '<span style="' +
                        style +
                        '">' +
                        body +
                        "!!!" +
                        "</span>";
                      //innerHtml += '<tr><td>' + span + '</td></tr>';
                      innerHtml2 += '<tr style="height: 5px;">';
                      arrSmi[index]?.forEach(
                        (it: any, id: number) =>
                          (innerHtml2 +=
                            `<td key=${id} style="border-left: 2px solid #FF7555; font-weight: 600; font-size: 20px; height: 5px; vertical-align: top; line-height: 20px; text-align: left; padding-right: 15px; padding-left: 5px;">` +
                            it?.mentions +
                            "</td>")
                      );
                      innerHtml2 += "</tr>";
                    });
                    innerHtml2 += "</tbody>";
                    let tableRoot2: any = tooltipEl.querySelector("#table2");
                    tableRoot2.innerHTML = innerHtml2;
                  }

                  const position = context.chart.canvas.getBoundingClientRect();
                  //const bodyFont: any = Chart.helpers.toFont(tooltipModel.options.bodyFont);

                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = "absolute";
                  if (!isMobile)
                    tooltipEl.style.left =
                      position.left +
                      window.pageXOffset +
                      tooltipModel.caretX +
                      "px";
                  tooltipEl.style.top =
                    position.top + window.pageYOffset * 0.75 + "px";
                  //tooltipEl.style.font = bodyFont.string;

                  //tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                  tooltipEl.style.pointerEvents = "none";
                },
            },
          },*/
        }}
        plugins={[
          {
            id: "drawBar",
            beforeDraw(chart) {
              const { config } = chart;
            },
          },
        ]}
      />
    </div>
  );
};

export default BarChart;
