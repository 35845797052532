import { InvalidCredentialsError, UnexpectedError } from "../../domain/errors";
import { MainTopicsPostModel } from "../../domain/models/main-topics-post-model";
import { SummaryReferenceSourceRatingModel } from "../../domain/models/summary-reference-source-rating-model";
import { SummaryTopicModel } from "../../domain/models/summary-topic-model";
import { SummaryService } from "../../domain/usecases/summary-service";
import { HttpPostClient, HttpStatusCode } from "../../protocols/http";
import { topicMapper } from "./utils/topic-mapper";

export class RemoteSummaryService implements SummaryService {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpPostClient<any, any>
  ) {}

  async loadMainTopics (params: any): Promise<SummaryTopicModel[]> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/getMainTopics',
      body: {
        thread_id: params.threadId,
        referenceFilter: params.referenceFilter,
        period: params.period,
        type: params.type,
        start: params.start,
        limit: params.limit,
        from: params.from,
        to: params.to
      }
    })

    const items = httpResponse.body.items
    let dateStart = new Date();
    let dateFinish = params.period == 'month' ? dateStart.setMonth(dateStart.getMonth() - 1) : params.period == 'week' ? dateStart.setDate(dateStart.getDate() - 6) : dateStart;

    if (httpResponse.statusCode === HttpStatusCode.ok) {
      items.forEach((item:any) => {
        /*this.loadTopic(item.id, params.type, undefined, undefined, moment(!params.period ? params.from : dateStart)?.format("YYYY-MM-DD") + " 00:00:00", moment(!params.period ? params.to : new Date())?.format('YYYY-MM-DD') + ' 23:59:59')
          .then(resp => {
            item.topicsList = resp
          })
          .catch(() => {})*/
      })
    }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return items
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadTopic (id: string, type: 'smi' | 'social' | 'all', thread_id: any, referenceFilter: any, from: string, to: string): Promise<MainTopicsPostModel[]> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/getTopic',
      body: {
        id,
        type,
        thread_id,
        referenceFilter: referenceFilter,
        from,
        to
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return topicMapper(httpResponse.body.items)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadSelectTopic (id: string, type: 'smi' | 'social' | 'all', from: string, to: string): Promise<MainTopicsPostModel[]> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/getTopic',
      body: {
        id,
        type,
        from,
        to
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return topicMapper(httpResponse.body.items)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getPostCountLight (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/getPostCountLight',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadReferenceSourceRating (params: any): Promise<SummaryReferenceSourceRatingModel[]> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/getReferenceSourceRating',
      body: {
        thread_id: params.threadId,
        reference_id: params.referenceId,
        period: params.period,
        user_id: params.userId,
        type: params.type,
        from: params.from,
        to: params.to
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body.items
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadTrustDailyInfo (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/trustViewsGraph',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        filter: params.filter
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCurrentSmiParams (groupId: string): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/content/currentsmi',
      body: {
        group_id: groupId
      }
    })

    let items = httpResponse.body
    


    if (httpResponse.statusCode === HttpStatusCode.ok) {
      items.forEach((item:any) => {
        this.loadTopic(item.group_id, 'smi', undefined, undefined, "", "")
          .then(resp => {
            item.topicsList = resp
          })
          .catch(() => {})
      })
    }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return items
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadReferenceSourceTopics (params: any): Promise<any[]> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/getReferenceSourceTopics',
      body: {
        thread_id: params.threadId,
        reference_id: params.referenceId,
        reference_item_id: params.referenceItemId | 0,
        period: params.period,
        user_id: params.userId,
        type: params.type,
        start: 0,
        limit: 20,
        from: params.from,
        to: params.to
      }
    })

    const items = httpResponse.body.items
    let dateStart = new Date();
    let dateFinish = params.period == 'month' ? dateStart.setMonth(dateStart.getMonth() - 1) : params.period == 'week' ? dateStart.setDate(dateStart.getDate() - 6) : dateStart;

    if (httpResponse.statusCode === HttpStatusCode.ok) {
      items.forEach((item:any) => {
        /*this.loadTopic(item.id, params.type, params.thread_id, undefined, moment(!params.period ? params.from : dateStart)?.format("YYYY-MM-DD") + " 00:00:00", moment(!params.period ? params.to : new Date())?.format('YYYY-MM-DD') + ' 23:59:59')
          .then(resp => {
            item.topicsList = resp
          })
          .catch(() => {})*/
      })
    }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return items
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadTrustDailyPosts (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/trustdaily',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        filter: {
          referenceFilter: params.referenceFilter
        }
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadTrustDailyComments (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/commenttrustdaily',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        filter: {
          referenceFilter: params.referenceFilter
        }
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadStats (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b',
      body: {
        thread_id: params.thread_id,
        product_id: params.product_id,
        from: params.from,
        to: params.to,
        //referenceFilter: params.referenceFilter,
        type: "views",
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadTrustHourly (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/trusthourly',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadMediaIndexInfo (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/MediaIndex',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        product_id: params.product_id,
        user_id: params.user
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadShareOfVoiceInfo (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/ShareOfVoice',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        product_id: params.product_id,
        user_id: params.user
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCompareCompetitorsInfo (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/compareCompetitors',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        product_id: params.product_id,
        user_id: params.user
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadThematicRatio (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/thematics/getThematicRatio',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        filter: {
          referenceFilter: params.referenceFilter
        },
        type: "thematics"
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadSex (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/age',
      body: {
        thread_id: params.thread_id,
        from: params.from,
        to: params.to,
        product_id: params.product_id,
        type: "sex"
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadProductCityList (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/getProductCityList',
      body: {
        product_id: params.product_id,
        from: params.from,
        to: params.to,
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadProductCityListReverse (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/getProductCityListReverse',
      body: {
        product_id: params.product_id,
        from: params.from,
        to: params.to,
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCityReport (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/getcityreport',
      body: {
        product_id: params.product_id,
        city: params.city
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadAuthors (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/uniqueAuthorsGraph',
      body: {
        thread_id: params.thread_id,
        product_id: params.product_id,
        from: params.from,
        to: params.to,
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadClientStats (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/stats',
      body: {
        thread_id: params.thread_id,
        product_id: params.product_id,
        from: params.from,
        to: params.to,
        network_id: params.network_id
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadSourceTop (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/sourceTop',
      body: {
        thread_id: params.thread_id,
        product_id: params.product_id,
        from: params.from,
        to: params.to,
        type: params.type,
        limit: params.limit
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadTrustViewsGraph (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/stats/trustViewsGraph',
      body: params
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadClientGraph (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/clientGraph',
      body: {
        thread_id: params.thread_id,
        product_id: params.product_id,
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadMap (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/thread/getcityreport',
      body: {
        thread_id: params.thread_id,
        city: params.city
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadAge (params: any): Promise<any> {
    const httpResponse = await this.httpClient.post({
      url: this.url + '/b2b/ages',
      body: {
        thread_id: params.thread_id,
        product_id: params.product_id,
        from: params.from,
        to: params.to,
        group1_start:"14",
        group1_end:"24",
        group2_start:"25",
        group2_end:"39",
        group3_start:"40",
        group3_end:"54",
        group4_start:"55",
        group4_end:"1000"
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
