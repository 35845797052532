import React, { useEffect, useState } from "react";
import { Button, Menu, MenuProps, Tooltip } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import "./main-nav-styles.scss";
import { IconMonitoring } from "../icons";
import { useSelector } from "react-redux";
import {
  getAppIsMobile,
  getAppMenuIsOpen,
  getProduct,
  getUserId,
} from "../../redux/selectors";
import { hideMenu, logoutAuth, setProduct, setProducts, setReferences, setReferencesFilter } from "../../redux/actions";
import { useAppDispatch } from "../../redux/store/hooks";
import { useNavigate } from "react-router-dom";
import { referencesService } from "../../api";

const MainNav: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useSelector(getAppIsMobile);
  //const menuIsOpen = useSelector(getAppMenuIsOpen);
  const userId = useSelector(getUserId);
  const dispatch = useAppDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [current, setCurrent] = useState("");
  const product = useSelector(getProduct);

  useEffect(() => {
      referencesService
        .loadClientProducts()
        .then((resp) => {
          dispatch(setProducts(resp));
            dispatch(setProduct(resp[0]?.id));
            dispatch(setReferencesFilter([resp[0]?.client?.id]));
            if (product == null)
              dispatch(setReferences([resp[0]?.client?.id]));

        })
        .catch((err) => {
          //dispatch(logoutAuth());
        });
  }, []);

  useEffect(() => {
    setCurrent(window.location.pathname.split("/")[1]);
    setMenuIsOpen(false)
  }, [window.location.pathname]);

  const closeMenu = (evt: any): void => {
    evt.stopPropagation();
    dispatch(hideMenu());
  };

  const handleClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  const preventClick = (evt: any): void => {
    evt.stopPropagation();
  };

  const items: any = [
    {
      label: "Главная",
      key: "main",
    },
    {
      label: "Лента",
      key: "thread",
    },
    /*{
      label: "Аудитория",
      key: "audience",
    },*/
    {
      label: "Источники",
      key: "sources",
    },
    {
      label: "Отчеты",
      key: "reports",
    },
  ];

  return (
    <div onClick={closeMenu} className={menuIsOpen && isMobile ? "main-nav" : "null-length main-nav"}>
      <div className="main-nav__wrap" onClick={preventClick}>
        <div className="main-nav__title" onClick={() => setMenuIsOpen(true)}>
          <IconMonitoring />
          СНИПР
        </div>
        {menuIsOpen && isMobile && (
          <Button
            className="main-nav__btn"
            onClick={() => setMenuIsOpen(false)}
          >
            <CloseOutlined />
          </Button>
        )}

        <Menu
          onClick={handleClick}
          className={!menuIsOpen && isMobile ? "hide" : ""}
          items={items}
          selectedKeys={[current]}
          mode="inline"
        />
      </div>
    </div>
  );
};

export default MainNav;
