import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ru_RU";
import Link from "./icons/Link";
import { useSelector } from "react-redux";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { getReferencesFilter, getThread } from "../redux/selectors";
import { postService, summaryService } from "../api";

const BestThemes: React.FC = () => {
  const [value, setValue] = useState("today");
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const feedFilter = useSelector(getFeedFilter);
  const [loading, setLoading] = useState(true);
  const [postList, setPostList] = useState<any>([]);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const thread = useSelector(getThread);
  const [type, setType] = useState("attendance");
  const referencesFilter = useSelector(getReferencesFilter);

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params = {
        thread_id: +thread.id,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        referenceFilter: referencesFilter,
      };
      summaryService
        .loadThematicRatio(params)
        .then((list) => {
          const newPostList: any = [...list.items];
          newPostList.sort((a: any, b: any) => b.count - a.count);
          setPostList(newPostList.slice(0, 5));
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [thread, dateString, type, referencesFilter]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];

  const sourceNums = thread?.networks;

  const data: any = [];
  postList?.map((post: any, id: number) => {
    data.push({
      key: id + 1,
      title: post?.title,
      count: post?.count,
      uri: post?.uri,
      id: post?.thematic_id,
    });
  });

  const columns = [
    {
      title: "№",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Тематика",
      dataIndex: "title",
      key: "title",
      width: 400,
    },
    {
      title: "Количество публикаций",
      dataIndex: "count",
      key: "count",
      render: (num: number) => (
        <div className="flex">{num.toLocaleString()}</div>
      ),
    },
    {
      title: "Отчет по тематике",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <a
          onClick={()=> getReport(`?thread_id=${
            thread?.id
          }&from=${dateString[0].slice(0, 10) + " 00:00:00"}&to=${
            dateString[1].slice(0, 10) + " 23:59:59"
          }&filter[thematicFilter][0]=${id}&sort[type]=date&sort[order]=desc&filter[trustoption]=${
            feedFilter.tonal
          }&filter[repostoption]=whatever${sourceNums
            .map((item:any, id: any) => `&filter[network_id][${id}]=${item}`)
            .join("")}&ajax=1`)}
        >
          Скачать <Link />
        </a>
      ),
    },
  ];

  const convert = (resp: any) => {
    let base64String = resp.content;
    const base64Data = base64String.replace(/^data:.+;base64,/, "");
    const byteCharacters = atob(base64Data); // Decode Base64 string
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: resp.mime });
    const url = URL.createObjectURL(blob);

    // Create a link element to download the file
    const link = document.createElement("a");
    link.href = url;
    link.download = resp.filename;
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  const getReport = (params?: string) => {
    setLoading(true);
    postService
      .loadPostsReport(params)
      .then((resp) => {
        convert(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const handleChangeType = (value: string) => {
    setType(value);
  };

  return (
    <div className="white-block">
      <div className="flex-space">
          <p className="block-title">
            Лучшие тематики
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
          <div className="flex">
            {/*
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      activeBorderColor: "#7856FE",
                    },
                  },
                }}
              >
                <Select
                  value={type}
                  onChange={handleChangeType}
                  style={{ width: 150 }}
                  allowClear={false}
                  options={[
                    { value: "attendance", label: "По охвату" },
                    { value: "err", label: "По ERR" },
                  ]}
                />
              </ConfigProvider>
                */}
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    buttonSolidCheckedColor: "#7856FE",
                    buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                    colorPrimary: "rgba(0, 0, 0, 0.1)",
                    buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                optionType="button"
                buttonStyle="solid"
              />
            </ConfigProvider>

            {value == "period" && (
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={onPeriodChange}
                style={{ width: "278px" }}
                className="choose-period"
                allowClear={false}
                locale={locale}
              />
            )}
          </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader">
          <LoadingOutlined />
        </div>
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 12,
              },
            },
          }}
        >
          <Table
            bordered={false}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </ConfigProvider>
      )}
      <Drawer title="Что отображает эта таблица?" onClose={onClose} open={open}>
        <p className="purple-border">
          Таблица иллюстрирует наиболее популярные тематики в контексте
          упоминаний компании по количеству публикаций за выбранный период.
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default BestThemes;
