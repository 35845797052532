import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
} from "antd";
import React, { useEffect, useState } from "react";
import LineChart from "./LineChart";
import locale from "antd/es/date-picker/locale/ru_RU";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { useSelector } from "react-redux";
import { getProduct, getReferencesFilter, getThread } from "../redux/selectors";
import { summaryService } from "../api";
import Coverage from "./Coverage";

const Metrics: React.FC = () => {
  const [value, setValue] = useState("month");
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const feedFilter = useSelector(getFeedFilter);
  const [loading, setLoading] = useState(true);
  const thread = useSelector(getThread);
  const product = useSelector(getProduct);
  const start = new Date();
  start.setDate(start.getDate() - 29);
  const [dateString, setDateString] = useState([
    start.toJSON().split("T")[0],
    new Date().toJSON().split("T")[0],
  ]);
  const [graphData, setGraphData] = useState([]);
  const [sumCount, setSumCount] = useState(0);
  const referencesFilter = useSelector(getReferencesFilter);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];

  useEffect(() => {
    setLoading(true);
    if (thread && dateString) {
      let params = {
        thread_id: +thread.id,
        product_id: product,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        referenceFilter: referencesFilter,
      };

      summaryService
        .loadStats(params)
        .then((resp) => {
          setGraphData(resp.graph_data);
          let sum = 0;
          resp.graph_data.forEach(function (num: any) {
            sum += num[1];
          });
          setSumCount(sum);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, product, referencesFilter]);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  return (
    <div className="white-block">
      {/*<Coverage title={"attendance"} />*/}
      <div className="white-block-small">
        <div className="flex-space">
          <p className="block-title">
            Вовлеченность
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
          <div className="flex">
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    buttonSolidCheckedColor: "#7856FE",
                    buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                    colorPrimary: "rgba(0, 0, 0, 0.1)",
                    buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                optionType="button"
                buttonStyle="solid"
              />
            </ConfigProvider>

            {value == "period" && (
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={onPeriodChange}
                style={{ width: "278px" }}
                className="choose-period"
                allowClear={false}
                locale={locale}
              />
            )}
          </div>
        </div>
        {loading ? (
          <div className="feed-posts-loader">
            <LoadingOutlined />
          </div>
        ) : (
          <div style={{"height": '400px'}}>
            <div className="title-big  margin-bottom">
              {(+sumCount)?.toLocaleString()}
            </div>
            <LineChart graphData={graphData} />
          </div>
        )}
      </div>
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          График иллюстрирует общую сумму комментариев, лайков (реакций) и
          репостов сообщений за выбранный период.
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики упоминаний.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default Metrics;
