export const SET_APP_IS_INITIALIZE = 'app/setAppIsInitialize'
export const SET_APP_IS_MOBILE = 'app/setAppIsMobile'
export const SET_THREAD = 'app/setThread'
export const SET_PRODUCT = 'app/setProduct'
export const SET_PRODUCTS = 'app/setProducts'
export const SET_THREADS = 'app/setThreads'
export const SET_REFERENCES = 'app/setReferences'
export const SET_REFERENCES_FILTER = 'app/setReferencesFilter'
export const SHOW_MENU = 'app/showMenu'
export const HIDE_MENU = 'app/hideMenu'

export const SET_SELECTED_REFERENCES_ALL = 'setSelectedReferencesAll'
export const SET_SELECTED_REFERENCES_SUBJECT = 'setSelectedReferencesSubject'
export const SET_SELECTED_REF_SOURCE = 'setSelectedRefSource'
export const SET_SELECTED_REFERENCES_EVENT = 'setSelectedReferencesEvent'
export const SET_SELECTED_REFERENCES_SPHERE = 'setSelectedReferencesSphere'
export const REMOVE_SELECTED_REFERENCES = 'removeSelectedReferences'

export const SET_FEED_FILTER = 'setFeedFilter'
export const SHOW_FEED_FILTER = 'showFeedFilter'
export const HIDE_FEED_FILTER = 'hideFeedFilter'
export const RESET_FEED_FILTER = 'resetFeedFilter'
export const RESET_PRODUCT = 'resetProduct'
export const REMOVE_FEED_FILTER = 'removeFeedFilter'

export const FETCH_POSTS_START = 'fetchPostsStart'
export const FETCH_POSTS_SUCCESS = 'fetchPostsSuccess'
export const FETCH_POSTS_FAIL = 'fetchPostsFail'
export const FETCH_POST_ATTENDANCE_SUCCESS = 'fetchPostAttendanceSuccess'
export const FETCH_POST_ATTENDANCE_FAIL = 'fetchPostAttendanceFail'
export const FETCH_POST_CHANGE_TRUST_START = 'fetchPostChangeTrustStart'
export const FETCH_POST_CHANGE_TRUST_SUCCESS = 'fetchPostChangeTrustSuccess'
export const FETCH_POST_CHANGE_TRUST_FAIL = 'fetchPostChangeTrustFail'
export const FETCH_SOURCE_REMOVE_START = 'fetchSourceRemoveStart'
export const FETCH_SOURCE_REMOVE_SUCCESS = 'fetchSourceRemoveSuccess'
export const FETCH_SOURCE_REMOVE_FAIL = 'fetchSourceRemoveFail'
export const FETCH_POST_REMOVE_START = 'fetchPostRemoveStart'
export const FETCH_POST_REMOVE_SUCCESS = 'fetchPostRemoveSuccess'
export const FETCH_POST_REMOVE_FAIL = 'fetchPostRemoveFail'
export const FETCH_POST_CANCEL_REMOVE_START = 'fetchPostCancelRemoveStart'
export const FETCH_POST_CANCEL_REMOVE_SUCCESS = 'fetchPostCancelRemoveSuccess'
export const FETCH_POST_CANCEL_REMOVE_FAIL = 'fetchPostCancelRemoveFail'
export const SET_POST_LIST = 'setPostList'
export const SET_POST_COUNT = 'setPostCount'
export const SET_POST_PAGE_NUMBER = 'setPostPageNumber'
export const SET_POST_REPORT_LINK = 'setPostReportLink'
export const SET_POST_PAGE_SIZE = 'setPostPageSize'
export const SET_STOP_POST_LIST = 'setStopPostList'
export const SET_REF_SOURCES = 'setRefSources'

export const FETCH_FEED_STATISTICS_START = 'fetchFeedStatisticsStart'
export const FETCH_FEED_STATISTICS_SUCCESS = 'fetchFeedStatisticsSuccess'
export const FETCH_FEED_STATISTICS_FAIL = 'fetchFeedStatisticsFail'
export const SET_FEED_STATISTICS = 'setFeedStatistics'
export const RESET_FEED_STATISTICS = 'resetFeedStatistics'

export const SHOW_CREATE_EVENT_MODAL = 'showCreateEventModal'
export const CLOSE_CREATE_EVENT_MODAL = 'closeCreateEventModal'
export const SEND_CREATE_EVENT_FORM = 'sendCreateEventForm'
export const NEW_SELECTED_ID = 'sendCreateEventForm'

export const SHOW_CHANGE_POST_TONAL_MODAL = 'changePostModal/show'
export const CLOSE_CHANGE_POST_TONAL_MODAL = 'changePostModal/close'
export const SET_CHANGE_POST_TONAL_IS_LOADING = 'changePostModal/setIsLoading'

export const SHOW_CHANGE_TONAL_REQUEST_MODAL = 'changeTonalRequestModal/show'
export const CLOSE_CHANGE_TONAL_REQUEST_MODAL = 'changeTonalRequestModal/close'
export const SET_CHANGE_TONAL_REQUEST_IS_LOADING = 'changeTonalRequestModal/setIsLoading'

export const SHOW_CONFIRM_DELETE_POST_MODAL = 'confirmDeletePostModal/show'
export const CLOSE_CONFIRM_DELETE_POST_MODAL = 'confirmDeletePostModal/close'
export const SET_CONFIRM_DELETE_POST_MODAL_IS_LOADING = 'confirmDeletePostModal/setIsLoading'

export const SET_USER = 'setUser'
export const RESET_USER = 'resetUser'

export const LOGIN_START = 'loginStart'
export const LOGIN_SUCCESS = 'loginSuccess'
export const LOGIN_FAIL = 'loginFail'
export const LOGOUT_START = 'auth/logoutStart'
export const LOGOUT_SUCCESS = 'logoutSuccess'
export const LOGOUT_FAIL = 'logoutFail'
export const AUTH_SET_IS_LOGGED_IN = 'auth/setIsLoggedIn'

export const SUMMARY_SET_TOPICS_LIST = 'summary/setTopicsList'
export const SUMMARY_FETCH_TOPICS_START = 'summary/fetchTopicsStart'
export const SUMMARY_FETCH_TOPICS_SUCCESS = 'summary/fetchTopicsSuccess'
export const SUMMARY_FETCH_TOPICS_FAIL = 'summary/fetchTopicsFail'
export const SUMMARY_SET_EVENT_TOPICS_LIST = 'summary/setEventTopicsList'
export const SUMMARY_FETCH_EVENT_TOPICS_START = 'summary/fetchEventTopicsStart'
export const SUMMARY_FETCH_EVENT_TOPICS_SUCCESS = 'summary/fetchEventTopicsSuccess'
export const SUMMARY_FETCH_EVENT_TOPICS_FAIL = 'summary/fetchEventTopicsFail'
export const SUMMARY_SET_REFERENCE_SOURCE_RATING_LIST = 'summary/setReferenceSourceRatingList'
export const SUMMARY_FETCH_REFERENCE_SOURCE_RATING_START = 'summary/fetchReferenceSourceRatingStart'
export const SUMMARY_FETCH_REFERENCE_SOURCE_RATING_SUCCESS = 'summary/fetchReferenceSourceRatingSuccess'
export const SUMMARY_FETCH_REFERENCE_SOURCE_RATING_FAIL = 'summary/fetchReferenceSourceRatingFail'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_START = 'summary/fetchReferenceSourceTopicsStart'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_SUCCESS = 'summary/fetchReferenceSourceTopicsSuccess'
export const SUMMARY_FETCH_REFERENCE_SOURCE_THEMES_FAIL = 'summary/fetchReferenceSourceThemesFail'
export const SUMMARY_FETCH_REFERENCE_SOURCE_THEMES_START = 'summary/fetchReferenceSourceThemesStart'
export const SUMMARY_FETCH_REFERENCE_SOURCE_THEMES_SUCCESS = 'summary/fetchReferenceSourceThemesSuccess'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_FAIL = 'summary/fetchReferenceSourceTopicsFail'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_DIGEST_RUBRIC_START = 'summary/fetchReferenceSourceTopicsDigestRubricStart'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_DIGEST_RUBRIC_SUCCESS = 'summary/fetchReferenceSourceTopicsDigestRubricSuccess'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_DIGEST_RUBRIC_FAIL = 'summary/fetchReferenceSourceTopicsDigestRubricFail'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_DIGEST_SPHERE_START = 'summary/fetchReferenceSourceTopicsDigestSphereStart'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_DIGEST_SPHERE_SUCCESS = 'summary/fetchReferenceSourceTopicsDigestSphereSuccess'
export const SUMMARY_FETCH_REFERENCE_SOURCE_TOPICS_DIGEST_SPHERE_FAIL = 'summary/fetchReferenceSourceTopicsDigestSphereFail'

export const SET_TOPICS_MODAL_IS_OPEN = 'topicsModal/setIsOpen'
export const FETCH_TOPICS_MODAL_START = 'topicsModal/fetchStart'
export const FETCH_TOPICS_MODAL_SUCCESS = 'topicsModal/fetchSuccess'
export const FETCH_TOPICS_MODAL_FAIL = 'topicsModal/fetchFail'

export const FETCH_MAIL_ITEM_ADD_START = 'mailing/fetchMailItemAddStart'
export const FETCH_MAIL_ITEM_ADD_SUCCESS = 'mailing/fetchMailItemAddSuccess'
export const FETCH_MAIL_ITEM_ADD_FAIL = 'mailing/fetchMailItemAddFail'
export const FETCH_MAIL_ITEM_REMOVE_START = 'mailing/fetchMailItemRemoveStart'
export const FETCH_MAIL_ITEM_REMOVE_SUCCESS = 'mailing/fetchMailItemRemoveSuccess'
export const FETCH_MAIL_ITEM_REMOVE_FAIL = 'mailing/fetchMailItemRemoveFail'
