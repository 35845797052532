import { useEffect, useState } from "react";
import { Image, Modal, Tooltip } from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  ExportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import IconReaction from "./icons/reaction";
import IconPeople from "./icons/people";
import IconVisibility from "./icons/visibility";
import IconFavorite from "./icons/favorite";
import IconComment from "./icons/comment";
import IconReply from "./icons/reply";
import { FeedPostModel } from "../api/domain/models/feed-post-model";
import { postService } from "../api";
import { useSelector } from "react-redux";
import { getReferences, getThread } from "../redux/selectors";
import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";
import { serializeDate } from "../api/usecases/utils/serialize-date";
import NetworkIcon from "../scripts/network-icon";
import LoadedImage from "./LoadedImage";
import { useAppDispatch } from "../redux/store/hooks";
import {
  addStopSource,
  backRemovedPost,
  changePostTrust,
  removeRefPost,
} from "../redux/actions";

type Props = {
  //post: FeedPostModel;
  type?: "topic";
  changeTonal: (post: any) => void;
};

const PostFull: any = ({ changeTonal }: Props) => {
  const [avatarNotLoaded, setAvatarNotLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState<any>({});
  const [commentList, setCommentList] = useState<any>([]);
  const thread = useSelector(getThread);
  const id = window.location.pathname.split("/")[2];
  const owner = window.location.pathname.split("/")[3];
  const network = window.location.pathname.split("/")[4];
  const reference = window.location.pathname.split("/")[5];
  const [deletedSource, setDeletedSource] = useState(false);
  const [deletedPost, setDeletedPost] = useState(false);
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tonal, setTonal] = useState(post.trust);
  const references = useSelector(getReferences);

  const HeartSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
      <title>heart icon</title>
      <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
    </svg>
  );

  const HeartIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={HeartSvg} {...props} />
  );

  useEffect(() => {
    loadPost();
  }, [thread]);

  const loadPost = () => {
    setLoading(true);
    if (thread) {
      let params = {
        id: id,
        owner_id: owner,
        network_id: network,
      };
      postService
        .loadSinglePost(params)
        .then((resp) => {
          setPost(resp);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params: any = {
        thread_id: +thread.id,
        from: post.created_date,
        //from: "2024-10-26 23:21:42",
        limit: 20,
        start: 0,
        owner_id: owner,
        post_id: id,
        //owner_id: "-25882042",
        //post_id: "1476823",
        network_id: network,
      };
      postService
        .loadCommentaries(params)
        .then((resp) => {
          let arr = [...resp.commentaries];
          arr.map((it: any) => {
            it["children_list"] = [];
            params["comment_id"] = it.id;
            it.children > 0 &&
              postService.loadChildCommentaries(params).then((child_resp) => {
                it["children_list"] = child_resp.commentaries;
              });
          });
          setLoading(false);
          setTimeout(() => setCommentList(arr), 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, post]);

  const getTitle = (title: any, id: any) => {
    if (id == 4 && title?.toUpperCase().slice(0, 11) == "КОММЕРСАНТЪ")
      return title.slice(0, 11);
    else return title;
  };

  const getPostText = (): { __html: string } => {
    return { __html: post.text };
  };

  const removeSource = (ownerId: string, networkId: string) => {
    dispatch(addStopSource(ownerId, networkId, references[0]));
    setDeletedSource(true);
  };

  const deletePost = () => {
    dispatch(
      removeRefPost(post.id, post.owner_id, post.network_id, +reference)
    );
    setDeletedPost(true);
  };

  const backPost = () => {
    dispatch(
      backRemovedPost(post.id, post.owner_id, post.network_id, +reference)
    );
    setDeletedPost(false);
  };

  const handleOk = (): void => {
    setLoading(true);
    dispatch(
      changePostTrust(
        post.id,
        post.owner_id,
        post.network_id,
        post.is_manual,
        tonal
      )
    );
    setLoading(false);
    setIsModalVisible(false);
    setTimeout(() => loadPost(), 1000);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  return (
    <div className="white-block">
      <div className="post-flex">
        <div className="media flex-start">
          <Image.PreviewGroup>
            {post?.images?.map((item: any, i: any) => (
              <LoadedImage item={item} key={i} />
            ))}
            {post?.media?.video?.length > 0 &&
              post?.media?.video?.map((item: any, id: any) => (
                <a className="video" href={item?.url} target="_blank" key={id}>
                  <Image
                    width={200}
                    src={item?.image}
                    fallback={item?.image}
                    key={id}
                  />
                  <i className="video-icon">
                    <CaretRightOutlined />
                  </i>
                </a>
              ))}
          </Image.PreviewGroup>
        </div>

        <div className="margin-bottom">
          <div className="post-statistics gray-block flex-space">
            {post?.network_id !== "4" && (
              <>
                <Tooltip
                  color={"#7856FE"}
                  title="Процент лайков по отношению к просмотрам"
                >
                  {post?.viewed != "0" && (
                    <div className="post-statistics__item flex-simple">
                      <IconReaction />
                      <span>
                        {Math.ceil((+post?.likes / +post?.viewed) * 100) + "%"}
                      </span>
                    </div>
                  )}
                </Tooltip>
                <Tooltip color={"#7856FE"} title="Лайки">
                  <div className="post-statistics__item flex-simple">
                    <IconFavorite />
                    <span>{post?.likes}</span>
                  </div>
                </Tooltip>
                <Tooltip color={"#7856FE"} title="Репосты">
                  <div className="post-statistics__item flex-simple">
                    <IconReply />
                    <span>{post?.reposts}</span>
                  </div>
                </Tooltip>
                <Tooltip color={"#7856FE"} title="Комментарии">
                  <div className="post-statistics__item flex-simple">
                    <IconComment />
                    <span>{post?.comments}</span>
                  </div>
                </Tooltip>
                <Tooltip color={"#7856FE"} title="Просмотры">
                  <div className="post-statistics__item flex-simple">
                    <IconVisibility />
                    <span>{post?.viewed}</span>
                  </div>
                </Tooltip>
                <Tooltip color={"#7856FE"} title="Охват">
                  <div className="post-statistics__item flex-simple">
                    <IconPeople />
                    <span>{post?.attendance}</span>
                  </div>
                </Tooltip>
              </>
            )}
          </div>
          <div>
            <div className="author__info flex-end">
              <div className="flex">
                {post?.created_date}
                <Tooltip color={"#7856FE"} title="Cсылка на публикацию">
                  <a
                    href={post?.uri}
                    target="_blank"
                    className="post-title__source"
                    rel="noreferrer"
                  >
                    <ExportOutlined />
                  </a>
                </Tooltip>
              </div>

              {deletedPost ? (
                <div>
                  Публикация исключена из выдачи
                  <div className="link-text" onClick={() => backPost()}>
                    Вернуть
                  </div>
                </div>
              ) : (
                <Tooltip color={"#7856FE"} title="Исключить публикацию">
                  <div className="delete-icon" onClick={deletePost}>
                    <DeleteOutlined />
                  </div>
                </Tooltip>
              )}
            </div>

            <div className="post-title flex">
              {
                <div className="post-source">
                  <a href={post?.author_url} target="_blank" rel="noreferrer">
                    {avatarNotLoaded ? (
                      <div className="user-widget">
                        <div className="user-img">
                          <div className={"flex-c"}></div>
                          {post?.author
                            ?.split(" ")[0]
                            ?.slice(0, 1)
                            .toUpperCase() || ""}{" "}
                          {post?.author
                            ?.split(" ")[1]
                            ?.slice(0, 1)
                            .toUpperCase() || ""}
                        </div>
                      </div>
                    ) : (
                      <img
                        src={post?.author_logo}
                        className="logo-icon"
                        alt=""
                        onError={() => setAvatarNotLoaded(true)}
                      />
                    )}
                    <div className="network-icon">
                      <NetworkIcon networkName={post?.network_name} />
                    </div>
                  </a>
                </div>
              }
              <div className="post-title__info block-title">
                <a
                  href={post?.author_url}
                  target="_blank"
                  className="post-title__source"
                  rel="noreferrer"
                >
                  {getTitle(post?.author, post?.network_id)}
                </a>
              </div>
              <div
                className="link-text"
                onClick={() => removeSource(post.owner_id, post.network_id)}
              >
                {deletedSource
                  ? "Источник будет исключен из выдачи"
                  : "Исключить источник"}
              </div>
            </div>
            <div
              onClick={() => setIsModalVisible(true)}
              className={`${
                post?.trust == -1
                  ? "red-trust"
                  : post?.trust == 1
                  ? "green-trust"
                  : "gray-trust"
              }`}
              key={post?.id}
            >
              {post?.trust == -1
                ? "Негативный"
                : post?.trust == 1
                ? "Позитивный"
                : "Нейтральный"}
            </div>
          </div>
          <div className="post__main">
            {post?.network?.id === "4" && (
              <div className="post-content__title">{post?.title}</div>
            )}

            <div className="text-container">
              <div dangerouslySetInnerHTML={getPostText()} />
            </div>
          </div>
        </div>
        <div>
          <div className="flex margin-bottom">
            <div className="post-title__info block-title">Комментарии</div>{" "}
            <p className="grey">{post?.statistics?.comments}</p>
          </div>
          {loading ? (
            <div className="feed-posts-loader">
              <LoadingOutlined />
            </div>
          ) : (
            <div>
              {commentList?.map((item: any, id: number) => (
                <div className="margin-bottom" key={id}>
                  <div className="flex-start">
                    <img src={item.author_logo} className="author-icon"></img>
                    <div>
                      {item.author}
                      <p className="light">{item.text}</p>
                    </div>
                  </div>
                  <div className="flex" style={{ marginLeft: 58 }}>
                    <div className="grey">
                      {serializeDate(item.created_date)}
                    </div>
                    <div>
                      <HeartIcon style={{ color: "red" }} /> {item.likes}
                    </div>
                  </div>
                  {/*item.children > 0 && <a>{item.children} ответа</a>*/}
                  {item?.children_list?.map((child: any, id: number) => (
                    <div
                      className="margin-bottom"
                      style={{ marginLeft: 58 }}
                      key={id}
                    >
                      <div className="flex-start">
                        <img
                          src={child.author_logo}
                          className="author-icon"
                        ></img>
                        <div>
                          {child.author}
                          <p className="light">{child.text}</p>
                        </div>
                      </div>
                      <div className="flex" style={{ marginLeft: 58 }}>
                        <div className="grey">
                          {serializeDate(child.created_date)}
                        </div>
                        <div>
                          <HeartIcon style={{ color: "red" }} /> {child.likes}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Тональность"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Изменить"
        className="change-post-tonal-modal__parent"
      >
        <div className="change-post-tonal-modal">
          {loading ? (
            <div className="feed-posts-loader">
              <LoadingOutlined />
            </div>
          ) : (
            <div className="change-post-tonal-modal__wrap">
              <div className="change-post-tonal-modal__list">
                <div
                  className={
                    tonal == 1
                      ? "green-active green flex-center"
                      : "green-bg green flex-center"
                  }
                  onClick={() => setTonal(1)}
                >
                  Положительная
                </div>
                <div
                  className={
                    tonal == 0
                      ? "gray-active grey flex-center"
                      : "gray-block grey flex-center"
                  }
                  onClick={() => setTonal(0)}
                >
                  Нейтральная
                </div>
                <div
                  className={
                    tonal == -1
                      ? "red-active red flex-center"
                      : "red-bg red flex-center"
                  }
                  onClick={() => setTonal(-1)}
                >
                  Негативная
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PostFull;
