import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProduct, getThread } from "../redux/selectors";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import DoughnutChart from "./DoughnutChart";
import locale from "antd/es/date-picker/locale/ru_RU";
import { summaryService } from "../api";

const SexDonut: React.FC = () => {
  const thread = useSelector(getThread);
  const product = useSelector(getProduct);
  const feedFilter = useSelector(getFeedFilter);
  const [value, setValue] = useState("today");
  const [loading, setLoading] = useState(true);
  const { RangePicker } = DatePicker;
  const [total, setTotal] = useState(0);
  const [dataArr, setDataArr] = useState([]);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (thread) {
      let params = {
        thread_id: +thread.id,
        product_id: product,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
      };
      summaryService
        .loadSex(params)
        .then((resp) => {
          let summ: any =
            Number(resp.additional_data.sex.m) +
            Number(resp.additional_data.sex.w);
          let arr: any = [
            resp.additional_data.sex.m,
            resp.additional_data.sex.w,
          ];
          setTotal(summ);
          setDataArr(arr);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, product]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);

    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  return (
    <div className="white-block-small">
      <div className="">
        <p className="block-title">
          Пол
          <InfoCircleOutlined className="info" onClick={showDrawer} />
        </p>
        <div className="flex">
          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  buttonSolidCheckedColor: "#7856FE",
                  buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                  colorPrimary: "rgba(0, 0, 0, 0.1)",
                  buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                },
              },
            }}
          >
            <Radio.Group
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
          </ConfigProvider>
          {value == "period" && (
            <RangePicker
              format={"DD-MM-YYYY"}
              onChange={onPeriodChange}
              style={{ width: "278px" }}
              className="choose-period"
              allowClear={false}
              locale={locale}
            />
          )}
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader" style={{ height: "300px" }}>
          <LoadingOutlined />
        </div>
      ) : total == 0 ? (
        <div className="feed-posts-loader" style={{ height: "300px" }}>
          Данных пока нет
        </div>
      ) : (
        <div>
          <DoughnutChart
            labelsArr={["мужской", "женский"]}
            dataArr={dataArr}
            summ={total}
          />
          <div className="legend-string">
            <div className="legend-square_1"></div>
            <p>Мужской</p>
            <b>{((dataArr[0] * 100) / total).toFixed(2)}%</b>
          </div>

          <div className="legend-string">
            <div className="legend-square_2"></div>
            <p>Женский</p>
            <b>{((dataArr[1] * 100) / total).toFixed(2)}%</b>
          </div>
        </div>
      )}
      <Drawer title="Что отображает этот график?" onClose={onClose} open={open}>
        <p className="purple-border">
          График демонстрирует соотношение по половой принадлежности активной
          аудитории, взаимодействующей с публикациями (оставляют реакции:
          комментарии, лайки, репосты) за выбранный период
        </p>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default SexDonut;
