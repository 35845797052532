import axios, { AxiosResponse } from "axios";
import {
  HttpPostClient,
  HttpPostParams,
  HttpResponse,
} from "../../../protocols/http";

export class AxiosHttpClient implements HttpPostClient<any, any> {
  async post(params: HttpPostParams<any>): Promise<HttpResponse<any>> {
    let httpResponse: AxiosResponse<any>;
    try {
      httpResponse = await axios.post(
        params.url,
        params.body,
        params.url.includes("/getToken")
          ? {}
          : {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            }
      );
    } catch (error: any) {
      httpResponse = error.response;
    }
    return {
      statusCode: httpResponse?.status,
      body: httpResponse?.data,
    };
  }
}
