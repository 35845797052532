import { IState } from '../reducers'

export const getAppIsInitialize = (state: IState): boolean => state.app.isInitialize
export const getAppIsMobile = (state: IState): boolean => state.app.isMobile
export const getAppMenuIsOpen = (state: IState): boolean => state.app.menuIsOpen
export const getThread = (state: IState): any => state.app.thread
export const getProduct = (state: IState): any => state.app.product
export const getProducts = (state: IState): any => state.app.products
export const getThreads = (state: IState): any => state.app.threads
export const getReferences = (state: IState): any => state.app.references
export const getReferencesFilter = (state: IState): any => state.app.referencesFilter
